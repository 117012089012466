var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component"
  }, [_c('v-layout', {
    staticClass: "overflow-visible"
  }, [_c('v-flex', [_c('giftcard-list', {
    attrs: {
      "filter": _vm.filter
    }
  }, [_c('template', {
    slot: "header"
  }, [!_vm.categoryCode ? _c('span', {}, [_vm._v("모바일상품권")]) : _vm._e(), _vm.categoryCode == 'book' ? _c('span', {}, [_vm._v("도서교환권")]) : _vm._e(), _vm.categoryCode == 'bizgift' ? _c('span', {}, [_vm._v("e-bizGIFT")]) : _vm._e(), _vm.categoryCode == 'cultureland' ? _c('span', {}, [_vm._v("컬쳐랜드")]) : _vm._e(), _vm.categoryCode == 'afreecatv' ? _c('span', {}, [_vm._v("아프리카TV 별풍선")]) : _vm._e(), _vm.categoryCode == 'google-giftcard' ? _c('span', {}, [_vm._v("구글 기프트카드")]) : _vm._e(), _vm.categoryCode == 'food' ? _c('span', {}, [_vm._v("외식·커피·케익 쿠폰")]) : _vm._e(), _vm.categoryCode == 'life' ? _c('span', {}, [_vm._v("주유·편의점·생활 쿠폰")]) : _vm._e(), _vm.categoryCode == 'giftpop' ? _c('span', {}, [_vm._v("기프트팝")]) : _vm._e()])], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }